import React, { useEffect } from 'react'
import Seo from './../../components/seo/seo'
import Layout from './../../components/layout/Layout'
import { Link } from 'gatsby'

const FlutterDeveloper = () => {
  useEffect(() => {
    setTimeout(() => {
      window?.$(function () {
        window?.$('input').change(function () {
          if (
            window?.$('#exampleCheck1').is(':checked') &&
            window?.$('#name').val() != '' &&
            window?.$('#email').val() != ''
          ) {
            window?.$('#job-open-form-submit-btn').removeAttr('disabled')
          } else {
            window?.$('#job-open-form-submit-btn').attr('disabled', 'disabled')
          }
        })
      })

      window?.$("input[type='file']").on('change', function () {
        if (this.files && this.files[0].size > 1068157) {
          window
            ?.Toastify({
              text: 'Please upload file less than 1MB. Thanks!!',
              duration: 5000,
              newWindow: true,
              gravity: 'bottom', // `top` or `bottom`
              position: 'center', // `left`, `center` or `right`
              stopOnFocus: true, // Prevents dismissing of toast on hover
              style: {
                background: 'linear-gradient(to right, #D7816A, #BD4F6C)',
              },
            })
            .showToast()
          window?.$('#job-open-form-submit-btn').attr('disabled', 'disabled')
          window?.$('#fileUpload').val('')
          const btnTextChange = document.querySelector('#fileTextChange')
          btnTextChange.innerHTML = `<i class="fi-rr-cloud-upload me-2 ms-0"></i> Upload a file`
        }
      })

      // call email api
      window?.$(document).ready(function () {
        window?.$('#job-open-form').on('submit', function (e) {
          e.preventDefault()
          window?.$('#job-open-form-submit-btn').text('Please wait..')
          window?.$('#job-open-form-submit-btn').prop('disabled', true)

          var formdata = new FormData(this)

          window?.$.ajax({
            url: 'https://api.immence.com/email/job-application',
            type: 'POST',
            cache: false,
            data: formdata,
            contentType: false,
            processData: false,
            success: function (response) {
              window
                ?.Toastify({
                  text: 'Thank you for contacting us. We will get back to you soon.',
                  duration: 5000,
                  newWindow: true,
                  gravity: 'bottom', // `top` or `bottom`
                  position: 'center', // `left`, `center` or `right`
                  stopOnFocus: true, // Prevents dismissing of toast on hover
                  style: {
                    background: 'linear-gradient(to right, #63A4FF, #0BAB64)',
                  },
                })
                .showToast()

              window?.$('#job-open-form-submit-btn').text('Send Message')
              window?.$('#job-open-form-submit-btn').prop('disabled', true)
              window?.$('#job-open-form').trigger('reset')
              window?.$('#fileUpload').val('')
              const btnTextChange = document.querySelector('#fileTextChange')
              btnTextChange.innerHTML = `<i class="fi-rr-cloud-upload me-2 ms-0"></i> Upload a file`
            },
            error: function (response) {
              window
                ?.Toastify({
                  text: 'Something went wrong. Please try again later.',
                  duration: 5000,
                  newWindow: true,
                  gravity: 'bottom', // `top` or `bottom`
                  position: 'center', // `left`, `center` or `right`
                  stopOnFocus: true, // Prevents dismissing of toast on hover
                  style: {
                    background: 'linear-gradient(to right, #D7816A, #BD4F6C)',
                  },
                })
                .showToast()

              window?.$('#job-open-form-submit-btn').text('Send Message')
              window?.$('#job-open-form-submit-btn').prop('disabled', false)
            },
          })
        })
      })
    }, 1000)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      window.AOS.init({
        duration: 1200,
        easing: 'ease-out-back',
      })
      var rellax = new window.Rellax('.rellax', {
        breakpoints: [576, 768, 1201],
      })
      const fileUpload = document.querySelector('#fileUpload')
      const btnTextChange = document.querySelector('#fileTextChange')

      fileUpload.addEventListener('change', (e) => {
        console.log(fileUpload.files)
        btnTextChange.innerHTML = `window.${fileUpload.files[0].name}`
      })
      // window.Dropzone.myGreatDropzone = {
      //     paramName: "file", // The name that will be used to transfer the file
      //     maxFilesize: 2, // MB
      //     dictDefaultMessage:
      //         "<strong>Drop files here or click to upload. </strong></br> (This is just a demo dropzone. Selected files are not actually uploaded.)",
      // };
    }, 1000)
  }, [])

  return (
    <>
      <Seo
        title={'Careers | immence'}
        description={'We are always in search of talented people.'}
      />
      <Layout>
        <div>
          <section className="com-section bg-blue hero-section policySection">
            <div className="hero-section-content">
              <div className="container">
                <div className data-aos="fade-up">
                  <h1 className="text-white hero-heading">
                    Business Development Executive
                  </h1>
                  <div className="d-flex text-white">
                    <div className="job-type me-2">
                      <p>Full time</p>
                    </div>
                    <div className="exp-yrs me-2">
                      <span>•</span>
                      <p>1-5 Years</p>
                    </div>
                    <div className="exp-yrs">
                      <span>•</span>
                      <p>Fresher can apply</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="com-section job-opening-overview bg-gray">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-5">
                  <div className="overview-title" data-aos="fade-up">
                    <h2 className="hero-heading">Overview</h2>
                  </div>
                </div>
                <div className="col-md-6 col-lg-7">
                  <div className="overview-content" data-aos="fade-up">
                    <p>
                      We are looking for a resilient, empathic Business
                      Development Executive to contribute to the growth of our
                      company. Business development executives are responsible
                      for finding and retaining clients for the provided
                      services, and remaining abreast of changes in consumption.
                      You will also be required to build capacity in staff
                      through regular training and mentorship.
                    </p>
                    <p>
                      To be successful as a business development executive, you
                      should attend all possible business events with the
                      intention of attracting and retaining clientele.
                      Ultimately, an outstanding business development executive
                      will keep a close eye on clients' feedback to ensure that
                      our services always exceed expectations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="com-section what-you-do bg-white">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-5">
                  <div className="overview-title" data-aos="fade-up">
                    <h2 className="hero-heading">Responsibilities</h2>
                  </div>
                </div>
                <div className="col-md-6 col-lg-7">
                  <div className="job-req" data-aos="fade-up">
                    <ul className="job-req-ul">
                      <li>
                        <p>
                          Familiarizing yourself with all services offered by
                          our company.
                        </p>
                      </li>
                      <li>
                        <p>
                          Developing and executing sales plans to meet and
                          exceed monthly and quarterly sales goals.
                        </p>
                      </li>
                      <li>
                        <p>
                          Growing business through the development of new leads
                          and new contacts.
                        </p>
                      </li>
                      <li>
                        <p>Identifying new revenue opportunities.</p>
                      </li>
                      <li>
                        <p>
                          Maintaining and updating sales, marketing and business
                          development documentation.
                        </p>
                      </li>
                      <li>
                        <p>Collaborating with management on sales goals.</p>
                      </li>
                      <li>
                        <p>
                          Support the team with other responsibilities as
                          required.
                        </p>
                      </li>
                      <li>
                        <p>
                          Generating business through online freelancing portals
                          like LinkedIn, Upwork, Freelancer, etc.
                        </p>
                      </li>
                      <li>
                        <p>
                          {' '}
                          Negotiating with clients to secure the most attractive
                          prices.
                        </p>
                      </li>
                      <li>
                        <p>
                          {' '}
                          Crafting business proposals and contracts to draw in
                          more revenue from clients.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="com-section what-you-do bg-gray">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-5">
                  <div className="overview-title" data-aos="fade-up">
                    <h2 className="hero-heading">Skills &amp; Requirements</h2>
                  </div>
                </div>
                <div className="col-md-6 col-lg-7">
                  <div className="job-req" data-aos="fade-up">
                    <ul className="job-req-ul">
                      <li>
                        <p>
                          Ability to maintain a high level of professionalism
                          and confidentiality.{' '}
                        </p>
                      </li>
                      <li>
                        <p>
                          Must be able to Calling, Drafting of proposals, Client
                          interaction, Negotiation, also coordinate with
                          technical team.{' '}
                        </p>
                      </li>
                      <li>
                        <p>Market research about the client to be targeted. </p>
                      </li>
                      <li>
                        <p>
                          Must have knowledge into Lead Generation and closure.{' '}
                        </p>
                      </li>
                      <li>
                        <p>
                          Must have experience with Client Communication and
                          Team handling.{' '}
                        </p>
                      </li>
                      <li>
                        <p>
                          High attention to detail and a focus on fact-based
                          decision making.{' '}
                        </p>
                      </li>
                      <li>
                        <p>Resourceful, with outstanding research skills. </p>
                      </li>
                      <li>
                        <p>Emboldened by challenges. </p>
                      </li>
                      <li>
                        <p>Proficiency in MS Office. </p>
                      </li>
                      <li>
                        <p>Experience in managing and growing sales teams. </p>
                      </li>
                      <li>
                        <p>Excellent multitasking skills. </p>
                      </li>
                      <li>
                        <p>Ability to prioritize tasks. </p>
                      </li>
                      <li>
                        <p>
                          Excellent verbal and written communication skills.{' '}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="com-section bg-white apply-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h2 className="hero-heading" data-aos="fade-up">
                    Apply Here
                  </h2>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="contact-form-box" data-aos="fade-up">
                    <form id="job-open-form">
                      <input
                        type="text"
                        name="job-application-type"
                        id="job-application-type"
                        defaultValue="Business Development Executive"
                        hidden
                      />
                      <div className="form-wrap row">
                        <div className="mb-4 form-group col-lg-6">
                          <label htmlFor="name" className="form-label required">
                            Full Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Type your name"
                            required
                          />
                        </div>
                        <div className="mb-4 form-group col-lg-6">
                          <label
                            htmlFor="email"
                            className="form-label required"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Type your email"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-wrap row">
                        <div className="mb-4 form-group col-lg-6">
                          <label
                            htmlFor="linkedinProfile"
                            className="form-label"
                          >
                            Linkedin Profile
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="linkedinProfile"
                            name="linkedinProfile"
                            placeholder="Type your linkedin profile"
                          />
                        </div>
                        <div className="mb-4 form-group col-lg-6">
                          <label
                            htmlFor="expectedSalary"
                            className="form-label"
                          >
                            Expected Salary
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="expectedSalary"
                            name="expectedSalary"
                            placeholder="Type your expected salary"
                          />
                        </div>
                      </div>
                      <div className="form-wrap row">
                        <div className="mb-4 form-group col-lg-12">
                          <label htmlFor="message" className="form-label">
                            Tell us about yourself
                          </label>
                          <textarea
                            name="message"
                            id="message"
                            className="form-control"
                            placeholder="Tell us about yourself"
                            style={{ height: 150 }}
                            defaultValue={''}
                          />
                        </div>
                      </div>
                      <div className="mb-4 form-group col-lg-12">
                        <label htmlFor="file" className="form-label d-block">
                          Upload your CV (or cool stuff we should see)
                        </label>
                        <div className="upload-btn-wrapper">
                          <div className="dropzone dropdown-setting">
                            <div className="upload-btn-wrapper estimate-wrapper mb-4">
                              <div className="d-inline position-relative">
                                <input
                                  className="form-control"
                                  id="fileUpload"
                                  name="file"
                                  type="file"
                                />
                                <button
                                  className="btn btn-outline-light btn-ouline-sm"
                                  id="fileTextChange"
                                >
                                  <i className="fi-rr-cloud-upload me-2 ms-0" />{' '}
                                  Upload a file
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div class="d-inline-block">
                <p class="ms-2 mb-0"><small>Max file size 10MB.</small></p>
              </div> */}
                      </div>
                      <div className="mb-5 form-check form-group">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                        />
                        <label
                          className="form-check-label text-white"
                          htmlFor="exampleCheck1"
                        >
                          I hereby accept{' '}
                          <Link
                            to="/terms-of-use"
                            target="_blank"
                            rel="noreferrer"
                            className="text-white text-underline"
                          >
                            Terms of Use
                          </Link>{' '}
                          and{' '}
                          <Link
                            to="/privacy-policy"
                            target="_blank"
                            rel="noreferrer"
                            className="text-white text-underline"
                          >
                            Privacy Policy
                          </Link>
                        </label>
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          id="job-open-form-submit-btn"
                          className="btn btn-outline-light-fill btn-ouline"
                          disabled="disabled"
                        >
                          Submit Application
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export default FlutterDeveloper
